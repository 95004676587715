import ReactDOM from 'react-dom/client';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space, message, Row, Col, Card } from 'antd';
import { t } from '@/src/locales/i18n';
import { getCsrfToken } from '@/src/rails_helper';

const App: React.FC = () => {
  const [loginButtonDisabled, setloginButtonDisabled] = useState(false);
  const [form] = Form.useForm();

  const onFinish = () => {
    setloginButtonDisabled(true);
    const loginForm = document.getElementById('login_form')! as HTMLFormElement;
    loginForm.submit();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const getInputEmail = () => {
    return document.getElementById('logonEmail')?.getAttribute('data-email');
  };

  const getFlashMessage = () => {
    try {
      const flash = sessionStorage.getItem('flash');
      if (flash) {
        sessionStorage.removeItem('flash');
        return JSON.parse(flash || '').message;
      }
    } catch (e) {
      console.warn('Failed to parse flash message from session storage');
      console.warn(e);
    }
    return null;
  };

  const getAlertMessage = () => {
    return document.getElementById('logonAlert')?.getAttribute('data-alert');
  };

  useEffect(() => {
    form.setFieldsValue({ email: getInputEmail() });

    const flashMessage = getFlashMessage();
    const alertMessage = getAlertMessage();
    if (alertMessage || flashMessage) {
      message.error(alertMessage || flashMessage);
    }
  }, []);

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col span={8}>
        <Space
          direction="vertical"
          align="center"
          size="large"
          style={{ display: 'flex', alignItems: 'stretch' }}
        >
          <Card title={t('entrypoints.employees.login.title')}>
            <Form
              form={form}
              action="/employees/sign_in"
              method="post"
              name="login_form"
              layout="vertical"
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Input
                type="hidden"
                name="authenticity_token"
                value={getCsrfToken()}
              />
              <Space direction="vertical" size="large" css={{ width: '100%' }}>
                <Space direction="vertical" css={{ width: '100%' }}>
                  <Form.Item
                    label={t('models.attributes.employee.email')}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'メールアドレスを入力してください。',
                      },
                    ]}
                  >
                    <Input name="employee[email]" />
                  </Form.Item>

                  <Form.Item
                    label={t('models.attributes.employee.password')}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'パスワードを入力してください。',
                      },
                    ]}
                  >
                    <Input.Password name="employee[password]" />
                  </Form.Item>
                </Space>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={loginButtonDisabled}
                  >
                    {t('entrypoints.employees.login.buttons.login')}
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Card>
        </Space>
      </Col>
    </Row>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
